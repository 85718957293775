/*eslint-disable */
import mock from "../mock";


const date=new Date();
const prevDay=new Date().getDate() - 1;
const nextDay=new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

// prettier-ignore
const nextMonth=date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) :
  new Date(date.getFullYear(), date.getMonth() + 1, 1);
// prettier-ignore
const prevMonth=date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) :
  new Date(date.getFullYear(), date.getMonth() - 1, 1);

const data={
  events: [
    {
      id: 1,
      url: "",
      title: "Design Review",
      start: date,
      end: nextDay,
      allDay: false,
      extendedProps: {
        calendar: "Business",
      },
    },
    {
      id: 2,
      url: "",
      title: "Meeting With Client",
      start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
      allDay: true,
      extendedProps: {
        calendar: "Business",
      },
    },
    {
      id: 3,
      url: "",
      title: "Family Trip",
      allDay: true,
      start: new Date(date.getFullYear(), date.getMonth() + 1, -9),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -7),
      extendedProps: {
        calendar: "Holiday",
      },
    },
    {
      id: 4,
      url: "",
      title: "Doctor's Appointment",
      start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
      allDay: true,
      extendedProps: {
        calendar: "Personal",
      },
    },
    {
      id: 5,
      url: "",
      title: "Dart Game?",
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: true,
      extendedProps: {
        calendar: "ETC",
      },
    },
    {
      id: 6,
      url: "",
      title: "Meditation",
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: true,
      extendedProps: {
        calendar: "Personal",
      },
    },
    {
      id: 7,
      url: "",
      title: "Dinner",
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: true,
      extendedProps: {
        calendar: "Family",
      },
    },
    {
      id: 8,
      url: "",
      title: "Product Review",
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: true,
      extendedProps: {
        calendar: "Business",
      },
    },
    {
      id: 9,
      url: "",
      title: "Monthly Meeting",
      start: nextMonth,
      end: nextMonth,
      allDay: true,
      extendedProps: {
        calendar: "Business",
      },
    },
    {
      id: 10,
      url: "",
      title: "Monthly Checkup",
      start: prevMonth,
      end: prevMonth,
      allDay: true,
      extendedProps: {
        calendar: "Personal",
      },
    },
  ],
};

// ------------------------------------------------
// GET: Return calendar events
// ------------------------------------------------
mock.onGet("/apps/calendar/events").reply(config => {
  // Get requested calendars as Array
  const calendars=config.calendars;

  return [200, data.events.filter(event => calendars.includes(event.extendedProps.calendar))];
});

// ------------------------------------------------
// POST: Add new event
// ------------------------------------------------
mock.onPost("/apps/calendar/add-event").reply(config => {
  // Get event from post data
  const {event}=JSON.parse(config.data);

  const {length}=data.events;
  let lastIndex=0;
  if (length) {
    lastIndex=data.events[length - 1].id;
  }
  event.id=lastIndex + 1;

  data.events.push(event);

  return [201, {event}];
});

// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------
mock.onPost("/apps/calendar/update-event").reply(config => {
  const {event: eventData}=JSON.parse(config.data);

  // Convert Id to number
  eventData.id=Number(eventData.id);

  const event=data.events.find(ev => ev.id === Number(eventData.id));
  Object.assign(event, eventData);

  return [200, {event}];
});

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete("/apps/calendar/remove-event").reply(config => {
  // Get event id from URL
  let {id}=config;

  // Convert Id to number
  const eventId=Number(id);

  const eventIndex=data.events.findIndex(ev => ev.id === eventId);
  data.events.splice(eventIndex, 1);
  return [200];
});
