import types from "../actionType";


const initialState={
  value: false,
  global: false,
  percent: 0,
};

export const loadingOnAction=(value="value", percent=0) => ({
  type: types.LOADING_ON,
  payload: {
    [value]: true,
    percent,
  },
});
export const loadingOffAction=() => ({
  type: types.LOADING_OFF,
  payload: initialState,
});

function loadingReducer(state=initialState, {payload, type}) {
  switch (type) {
    case types.LOADING_ON:
      return {...state, ...payload};
    case types.LOADING_OFF:
      return initialState;
    default:
      return state;
  }
}

export default loadingReducer;