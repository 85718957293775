import React, {lazy, Suspense} from "react";
import {createRoot} from "react-dom/client";
import {HashRouter} from "react-router-dom";

// ** Redux Imports
import {store} from "./redux/store";
import {Provider} from "react-redux";

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import {AbilityContext} from "./utility/context/Can";
import {ThemeContext} from "./utility/context/ThemeColors";

// ** ThemeConfig
import themeConfig from "./configs/themeConfig";

// ** Toast
import {Toaster} from "react-hot-toast";

// ** i18n
import "./configs/i18n";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** Fake Database
import "./@fake-db";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "antd/dist/reset.css";
import "./assets/scss/style.scss";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import {ConfigProvider} from "antd";

// ** Lazy load app
const LazyApp=lazy(() => import("./App"));

const container=document.getElementById("root");
const root=createRoot(container);

root.render(
  <HashRouter>
    <Provider store={store}>
      <ConfigProvider direction="rtl">
        <Suspense fallback={<Spinner />}>
          <AbilityContext.Provider value={ability}>
            <ThemeContext>
              <LazyApp />
              <Toaster position={themeConfig.layout.toastPosition} toastOptions={{className: "react-hot-toast"}} />
            </ThemeContext>
          </AbilityContext.Provider>
        </Suspense>
      </ConfigProvider>
    </Provider>
  </HashRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
