import types from "../actionType";


const initialState=[];

export const initSidebarAction=(payload) => ({
  type: types.SIDEBAR_INIT,
  payload,
});

function sidebarMenuReducer(state=initialState, {payload, type}) {
  switch (type) {
    case types.SIDEBAR_INIT:
      return payload;
    default:
      return state;
  }
}

export default sidebarMenuReducer;