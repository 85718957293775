import types from "../actionType";


const initialState={
  userData: {
    token: "",
    roles: [
      {
        number: 0,
        title: "",
        section_id: "",
        scope_id: "",
      },
    ],
    active_role: "",
    expired_at: "",
    multiRole: 0,
  },
};

function authReducer(state=initialState, {payload, type}) {
  switch (type) {
    case types.INIT_AUTH_DATA:
      return {...state, userData: {...payload}};
    case types.INIT_USER_INFO:
      return {...state, userInfo: {...payload}};
    case types.RESET_AUTH_DATA:
      return initialState;
    default:
      return state;
  }
}

export default authReducer;